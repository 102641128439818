import * as React from "react"
import styled, { css } from "styled-components"

type Padding = "none" | "regular" | "medium" | "large"

const getPadding = (padding: Padding) => {
  switch (padding) {
    case "medium": {
      return "48px"
    }

    case "large": {
      return "104px"
    }

    case "none": {
      return "0"
    }

    default: {
      return "24px"
    }
  }
}

interface ContainerProps {
  background: string | string[],
  backgroundType: "color" | "image"
  padding: Padding
  topPaddingOnly: boolean
  bottomPaddingOnly: boolean
  zigzag?: boolean
  ziggywiggy?: boolean
}

const Container = styled.div`
  position: relative;
  ${(props: ContainerProps) => {
    if (props.backgroundType === "color") {
      return css`
        background-color: ${props.background};
        padding-top: ${props.bottomPaddingOnly ? 0 : getPadding(props.padding)};
        padding-bottom: ${props.topPaddingOnly ? 0 : getPadding(props.padding)};

        ${ props.ziggywiggy ?
          css`
            clip-path: polygon(0% 80%,0% 0%,100% 0%,100% 100%,85% 96%,54% 100%,28% 90%,-200% 100%,100% 0,0 0,0 17%);

            @media only screen and (max-width: 575px) {
              clip-path: none;
            }
        `  :
          props.zigzag ?
            css`
            clip-path: polygon(0 90%, 0% 0%, 100% 0%, 100% 100%, 85% 94%, 54% 100%, 28% 94%, 13% 100%);

            @media only screen and (max-width: 575px) {
              clip-path: none;
            }` : null}
      `
    }

    const backIsArray = Array.isArray(props.background)
    return css`
      background-image: ${backIsArray ? props.background[0] : props.background};
      background-color: #fff;
      background-repeat: no-repeat;
      background-position: top left;
      padding-top: ${props.bottomPaddingOnly ? 0 : getPadding(props.padding)};
      padding-bottom: ${props.topPaddingOnly ? 0 : getPadding(props.padding)};

      @media only screen and (max-width: 1440px) {
        background-image: ${backIsArray ? props.background[1] : props.background};
      }

      @media only screen and (max-width: 1024px) {
        background-image: ${backIsArray ? props.background[2] : props.background};
      }

      @media only screen and (max-width: 768px) {
        background-image: ${backIsArray ? props.background[3] : props.background};
      }

      @media only screen and (max-width: 576px) {
        background-image: ${backIsArray ? props.background[4] : props.background};
        background-size: cover;
      }
    `
  }}
`

interface SectionProps {
  fullWidth?: boolean
  background?: string | string[]
  backgroundType?: "image" | "color"
  padding: Padding
  zigzag?: boolean
  /** If you want zigzag and triangle at top */
  ziggywiggy?: boolean
  topPaddingOnly?: boolean
  bottomPaddingOnly?: boolean
}

export default class Section extends React.Component<SectionProps> {
  render() {
    const {
      fullWidth = false,
      background = "#fff",
      backgroundType = "color",
      padding = "regular",
      zigzag = false,
      ziggywiggy = false,
      topPaddingOnly = false,
      bottomPaddingOnly = false,
      children
    } = this.props

    return (
      <Container
        className={`container${fullWidth ? "-fluid" : ""}`}
        background={background}
        backgroundType={backgroundType}
        padding={padding}
        zigzag={zigzag}
        ziggywiggy={ziggywiggy}
        topPaddingOnly={topPaddingOnly}
        bottomPaddingOnly={bottomPaddingOnly}
      >
        <div className="row">
          {children}
        </div>
      </Container>
    )
  }
}