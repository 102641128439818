import { N0 } from "@stackchat/colors"
import styled, { css } from "styled-components"

const getLozengeContainerStyles = (
  props: {
    color: string
    large: boolean
  }) => css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: ${props.large ? "4px" : "2px" };
  background-color: ${ props.color };
  color: ${ N0 };
  padding: ${props.large ? "4px 12px" : "2px 8px" };
  font-size: ${props.large ? "20px" : "12px" };
  font-weight: 500;
`

export const LozengeContainer = styled.div`
  ${ getLozengeContainerStyles }
`