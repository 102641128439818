import { N0, N20, N800, N90, P500 } from "@stackchat/colors"
import { Link } from "gatsby"
import styled from "styled-components"

export const DisplayDate = styled.div`
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  color: ${ N90 };

  div {
    display: none;
  }

  @media only screen and (max-width: 767.99px) {
    font-size: 12px;
    
    div {
      margin-left: 8px;
      display: block;
    }
  }
`

export const Title = styled.div`
  display: inline-flex;
  padding: 2px 8px;
  font-weight: 500;
  font-size: 20px;
  color: ${ N800 };
  transition: all 250ms linear;
  
  &:hover {
    text-decoration: underline;
    color: ${ P500 };
  }

  @media only screen and (max-width: 575.99px) {
    font-size: 16px;
    padding: 2px 0;
  }
`

export const Container = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  flex-wrap: wrap;
  background-color: ${ N0 };

  @media only screen and (max-width: 767.99px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    background-color: ${ N20 };
    padding: 8px;
    border-radius: 4px;
  }
`

export const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`