import { faChevronLeft, faChevronRight } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { N0, N30, N800, N90, P500, P800 } from "@stackchat/colors"
import { graphql, Link } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import * as React from "react"
import styled from "styled-components"
import Layout from "templates/layout"
import { ChangelogItemType, getColor } from "../components/changelog-item"
import * as DesignSystem from "../components/design-system"
import Holder from "../components/holder"
import Lozenge from "../components/lozenge"
import Section from "../components/section"

const DisplayDate = styled.p`
  color: ${ N90 };
  margin: 0;
  margin-bottom: 32px;
`

const Button = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 16px;
  /* height: 32px; */
  font-size: 20px;
  border-radius: 4px;
  background-color: ${ N30 };
  color: ${ N800 };
  cursor: pointer;
  font-weight: 500;

  &:hover {
    text-decoration: none;
    color: ${ P500 };
  }

  &:focus {
    background-color: ${ N800 };
    color: ${ N0 };
  }

  &.left {
    svg {
      margin-left: 8px;
    }
  }

  &.right {
    svg {
      margin-right: 8px;
    }
  }
`

interface ChangelogItemProps {
  data: {
    mdx: {
      body: string
      excerpt: string
      frontmatter: {
        title: string
        date: string
        previous?: string
        next?: string
        type: ChangelogItemType
      }
    }
  }
}

export default class ChangelogItem extends React.Component<ChangelogItemProps> {
  getFormattedTitle = () => {
    const { title } = this.props.data.mdx.frontmatter

    if (title.indexOf("`") < 0) {
      return (
        <DesignSystem.H3 style={{ marginTop: 8 }}>
          {title}
        </DesignSystem.H3>
      )
    }

    const regex = new RegExp(/`(.*?)`/, "g")
    const parts = title.split(regex)

    const formattedTitle = parts.reduce(
      (reduction, part) => {
        if (
          part.indexOf(" ") !== 0 &&
          part.indexOf(" ") !== part.length - 1
        ) {
          // this part is the code part
          reduction.push(
            <DesignSystem.InlineCode>
              {part}
            </DesignSystem.InlineCode>
          )
        } else {
          // this is normal text part
          reduction.push(
            <>
              {part}
            </>
          )
        }

        return reduction
      }, [] as React.ReactElement[]
    )


    return (
      <DesignSystem.H3 style={{ marginTop: 8 }}>
        {
          React.Children.map(formattedTitle, (child, index) => {
            return React.cloneElement(child, {
              key: index
            })
          })
        }
      </DesignSystem.H3>
    )
  }

  render() {
    const { body, frontmatter: { title, date, next, previous, type } } = this.props.data.mdx

    let actionClasses = ""

    if (!!previous && !!next) {
      actionClasses = "justify-content-between"
    } else if (!!previous && !next) {
      actionClasses = "justify-content-start"
    } else if (!previous && !!next) {
      actionClasses = "justify-content-end"
    }

    return (
      <Layout title={title} alwaysOnHeader={true}>
        <Section background="#fff" padding="large" topPaddingOnly={true}>
          <Holder width={12}>
            <DesignSystem.H2 className="text-center">
              Stackchat Studio Changelog
            </DesignSystem.H2>
            <hr style={{ maxWidth: 200, marginLeft: "auto", marginRight: "auto" }} />
          </Holder>
        </Section>

        <Section padding="regular" fullWidth={true}>
          <Holder width={12} className="col-12 col-lg-8 offset-lg-2">
            <Lozenge
              text={type}
              color={getColor(type)}
              large={true}
            />

            {this.getFormattedTitle()}

            <DisplayDate>
              {date}
            </DisplayDate>

            <MDXRenderer>{body}</MDXRenderer>
          </Holder>
        </Section>

        <Section padding="none" fullWidth={true}>
          <Holder
            width={12}
            className={`col-12 col-lg-8 offset-lg-2 d-flex align-items-center ${ actionClasses }`}
          >
            {
              previous ?
                (
                  <Button to={previous} className="right">
                    <FontAwesomeIcon icon={faChevronLeft} />
                    Previous
                  </Button>
                ) :
                null
            }

            {
              next ?
                (
                  <Button to={next} className="left">
                    Next
                    <FontAwesomeIcon icon={faChevronRight} />
                  </Button>
                ) :
                null
            }
          </Holder>
        </Section>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(frontmatter: {path: {eq: $slug}}) {
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        previous
        next
        type
      }
      body
    }
  }
`