import * as React from "react"
import { LozengeContainer } from "./styled"

interface LozengeProps {
  text: React.ReactNode
  color: string
  className?: string
  large?: boolean
}

export default class Lozenge extends React.Component<LozengeProps> {
  render() {
    const { className, color, text, large = false } = this.props

    return (
      <LozengeContainer {...{ color, large, className }}>
        {text}
      </LozengeContainer>
    )
  }
}