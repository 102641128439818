import { B500, G600, P500, R500, Y600 } from "@stackchat/colors"
import * as React from "react"
import { FormattedMessage } from "react-intl"
import Lozenge from "../lozenge"
import { Container, DisplayDate, StyledLink, Title } from "./styled"

export enum ChangelogItemType {
  Feature = "Feature",
  Deprecation = "Deprecation",
  Update = "Update",
  Bugfix = "Bugfix",
  Beta = "Beta",
}

enum ChangelogItemTypeValues {
  Feature = "changelog.item.type-feature",
  Deprecation = "changelog.item.type-deprecation",
  Update = "changelog.item.type-update",
  Bugfix = "changelog.item.type-bugfix",
  Beta = "changelog.item.type-beta",
}

export const getColor = (type: ChangelogItemType): string => {
  switch (type) {
    case ChangelogItemType.Beta: { return P500 }

    case ChangelogItemType.Bugfix: { return R500 }

    case ChangelogItemType.Deprecation: { return B500 }

    case ChangelogItemType.Feature: { return G600 }

    default: { return Y600 }
  }
}

interface ChangelogItemProps {
  title: string
  date: string
  type: ChangelogItemType
  link: string
}

export default class ChangelogItem extends React.Component<ChangelogItemProps> {
  getColor = (): string => {
    return getColor(this.props.type)
  }

  getDate = () => {
    const { date } = this.props
    const [day, month, year] = date.split(" ")

    if (parseInt(year, 10) === new Date().getFullYear()) {
      return `${ day } ${ month }`
    }

    return date
  }

  render() {
    const { title, type, link } = this.props

    return (
      <Container>
        <DisplayDate>
          {this.getDate()}

          <Lozenge
            text={<FormattedMessage id={ChangelogItemTypeValues[type]} />}
            color={this.getColor()}
          />
        </DisplayDate>

        <StyledLink to={link}>
          <Title>
            {title}
          </Title>
        </StyledLink>

        <Lozenge
          className="d-none d-md-inline-flex"
          text={<FormattedMessage id={ChangelogItemTypeValues[type]} />}
          color={this.getColor()}
        />
      </Container>
    )
  }
}