import * as React from "react"
import styled from "styled-components"

const Container = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  position: relative;
`

interface HolderProps {
  width: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  className?: string
}

export default class Holder extends React.Component<HolderProps> {
  render() {
    const { width, children, className = "" } = this.props

    return (
      <Container className={`col-12 col-sm-${width === 12 ? "12" : "6"} col-md-${width} ${className}`}>
        {children}
      </Container>
    )
  }
}